var MCS = {};

$(function() {
	MCS.utils.setTransition();
});

window.addEventListener('load', function() {
	$(window).on('mcs-init', function() {
		$(window).trigger('scroll');
		window.setTimeout(function() {
			$(window).trigger('scroll');
			$('body').first().removeClass('loading');
			$('.loader').fadeOut('slow');
		}, 500);
	});

	MCS.init();
});